import React from "react";

function LinkedinSvg() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="75.768"
			height="76.248"
			viewBox="0 0 75.768 76.248"
			className="linkedin"
		>
			<g id="Group_2" data-name="Group 2" transform="translate(-1028.546 -502.038)">
				<path
					id="path14"
					d="M243.836-96.826a5.532,5.532,0,0,1,5.6-5.462H314a5.532,5.532,0,0,1,5.6,5.462V-31.5a5.532,5.532,0,0,1-5.6,5.462H249.435a5.532,5.532,0,0,1-5.6-5.462Z"
					transform="translate(784.71 604.325)"
					fill="#999"
				/>
				<path
					id="path28"
					d="M266.8-38.46V-72.89H255.36v34.43Zm-5.722-39.131c3.991,0,6.475-2.644,6.475-5.948-.074-3.378-2.484-5.949-6.4-5.949s-6.474,2.571-6.474,5.949c0,3.3,2.483,5.948,6.324,5.948h.074Z"
					transform="translate(784.71 604.325)"
					fill="#fff"
					fillRule="evenodd"
				/>
				<path
					id="path30"
					d="M273.136-38.46H284.58V-57.687a7.844,7.844,0,0,1,.377-2.793,6.264,6.264,0,0,1,5.872-4.185c4.141,0,5.8,3.157,5.8,7.786V-38.46h11.443V-58.2c0-10.575-5.646-15.5-13.175-15.5a11.4,11.4,0,0,0-10.39,5.8h.076V-72.89H273.137c.15,3.231,0,34.43,0,34.43Z"
					transform="translate(784.71 604.325)"
					fill="#fff"
					fillRule="evenodd"
				/>
			</g>
		</svg>
	);
}

export default LinkedinSvg;
