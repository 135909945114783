import React from "react";

// Styles
import {FooterWrapper} from "./Footer.styles";

function Footer() {
	return <FooterWrapper>© {new Date().getFullYear()}, Kevin Mamaqi Kapllani</FooterWrapper>;
}

export default Footer;
