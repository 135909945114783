import styled from "styled-components";
import Colors from "theme/Colors";

export const BodyWrapper = styled.div`
	/* margin-left: 64px;
	margin-top: 150px;
	width: 840px;
	max-width: 840px; */
	width: 90%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100vh;
	justify-content: center;

	p {
		text-align: center;
		max-width: 640px;
		margin-bottom: 2rem;
		margin-top: 0;
	}

	/* ul {
		li {
			font-size: 18px;
			line-height: 27px;
			margin-bottom: 0.5rem;
		}
	} */
`;

export const CursorWrapper = styled.svg`
	fill: white;
	&.cursor--set {
		fill: black;
	}
	&.cursor--hover.cursor--set {
		fill: ${Colors.mainColor};
	}
`;
