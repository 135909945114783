import styled from "styled-components";
import Colors from "theme/Colors";

export const MainMenu = styled.nav`
	ul {
		padding-left: 0;
		li {
			display: block;
			line-height: 30px;
			a {
				color: #000;
				font-size: 18px;
				transition: color 0.5s;
				transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
				position: relative;
				font-weight: 500;

				&:before {
					content: "";
					position: absolute;
					z-index: -1;
					bottom: -3px;
					left: -8px;
					right: -8px;
					// width: 130%;
					height: 50%;
					opacity: 0.2;
					transform: scale3d(0, 1, 1);
					transform-origin: 0% 50%;
					transition: transform 0.5s;
					transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
					background: ${Colors.mainColor};
				}

				&:hover {
					// color: $mainColor;
					// font-weight: bold;

					&:before {
						transform: scale3d(1, 1, 1);
						transform-origin: 0% 50%;
						transition-timing-function: ease;
					}
				}

				&:focus {
					&:before {
						transform: scale3d(1, 1, 1);
						transform-origin: 0% 50%;
						transition-timing-function: ease;
					}
				}

				&.selected {
					&:before {
						transform: scale3d(1, 1, 1);
						transform-origin: 0% 50%;
						transition-timing-function: ease;
					}
				}
			}
		}
	}
`;

export const SocialMenu = styled.ul`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	list-style: none;
	margin: 0;
	padding: 0;
	margin-bottom: 30px;

	li {
		display: inline-block;
	}

	svg {
		width: 24px;
		height: 24px;
		transition: all 0.2s ease;

		path {
			transition: all 0.2s ease;
		}

		&:hover {
			cursor: none;
		}

		&.malt {
			margin-right: 12px;
			&:hover {
				path {
					fill: #fc5656;
				}
			}
		}

		&.linkedin {
			&:hover {
				path#path14 {
					fill: #069;
				}
			}
		}
	}
`;
