import React from "react";

function MaltSvg() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="186.795"
			height="186.795"
			viewBox="0 0 186.795 186.795"
			className="malt"
		>
			<g id="logo_malt" data-name="logo_malt" transform="translate(-619 -475.351)">
				<path
					id="Path_1"
					data-name="Path 1"
					d="M187.518,84.731c-14.1-14.1-29.162-4.974-38.588,4.452l-44.52,44.523L59.885,178.23c-9.426,9.425-19.292,23.746-4.452,38.584s29.161,4.975,38.585-4.451l44.523-44.523,44.523-44.522c9.427-9.427,18.552-24.489,4.454-38.587"
					transform="translate(591.334 417.573)"
					fill="#999"
				/>
				<path
					id="Path_2"
					data-name="Path 2"
					d="M102.445,81.084,121.3,99.938l19.192-19.192c1.3-1.306,2.626-2.518,3.957-3.661-2.01-10.14-7.8-19.307-23.158-19.307-15.391,0-21.17,9.2-23.168,19.365,1.436,1.243,2.871,2.489,4.322,3.941"
					transform="translate(591.334 417.573)"
					fill="#999"
				/>
				<path
					id="Path_3"
					data-name="Path 3"
					d="M140.482,221.137,121.3,201.956,102.457,220.8c-1.431,1.432-2.855,2.74-4.273,3.969,2.161,10.337,8.277,19.805,23.11,19.805,14.872,0,20.98-9.519,23.127-19.889-1.319-1.135-2.64-2.248-3.939-3.547"
					transform="translate(591.334 417.573)"
					fill="#999"
				/>
				<path
					id="Path_4"
					data-name="Path 4"
					d="M94.449,126.791H58.1c-13.329,0-30.433,4.2-30.433,24.136,0,14.877,9.522,20.986,19.894,23.132,1.228-1.417,46.889-47.268,46.889-47.268"
					transform="translate(591.334 417.573)"
					fill="#999"
				/>
				<path
					id="Path_5"
					data-name="Path 5"
					d="M195.1,127.759c-1.151,1.338-46.907,47.3-46.907,47.3h35.834c13.329,0,30.433-3.149,30.433-24.136,0-15.389-9.2-21.171-19.36-23.169"
					transform="translate(591.334 417.573)"
					fill="#999"
				/>
				<path
					id="Path_6"
					data-name="Path 6"
					d="M106.359,114.859l6.494-6.494L94.01,89.52c-9.427-9.425-23.746-19.292-38.586-4.452-10.882,10.882-8.465,21.473-2.693,30.071,1.758-.131,53.628-.28,53.628-.28"
					transform="translate(591.334 417.573)"
					fill="#999"
				/>
				<path
					id="Path_7"
					data-name="Path 7"
					d="M136.237,187l-6.511,6.511L148.92,212.7c9.426,9.427,24.488,18.551,38.586,4.453,10.52-10.521,8.106-21.571,2.29-30.423-1.872.135-53.559.267-53.559.267"
					transform="translate(591.334 417.573)"
					fill="#999"
				/>
			</g>
		</svg>
	);
}

export default MaltSvg;
