import {Link} from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Logo from "assets/img/logo-192.png";
import Malt from "assets/img/Malt.svg";
import Linkedin from "assets/img/Linkedin.svg";

// Styles
import {MainMenu, SocialMenu} from "./Header.styles";

const Header = ({siteTitle}) => (
	<header style={{}}>
		<Link to="/">
			<img src={Logo} alt="Kevin Mamaqi Kapllani" style={{maxWidth: 92, borderRadius: 46}} />
		</Link>
		<h1 style={{color: "#999", fontSize: 16, fontWeight: "normal"}}>Kevin Mamaqi Kapllani</h1>
		<SocialMenu>
			<li>
				<a target="_blank" href="https://www.malt.es/profile/kevinmamaqikapllani">
					<Malt />
				</a>
			</li>
			<li>
				<a target="_blank" href="https://www.linkedin.com/in/kevinmamaqi/">
					<Linkedin />
				</a>
			</li>
		</SocialMenu>
		<MainMenu>
			<ul>
				<li>
					<Link activeClassName="selected" to="/about">
						About
					</Link>
				</li>
				<li>
					<Link activeClassName="selected" to="/projects">
						Projects
					</Link>
				</li>
				<li>
					<Link activeClassName="selected" to="/business">
						Business
					</Link>
				</li>
				<li>
					<Link activeClassName="selected" to="/work">
						Work
					</Link>
				</li>
				<li>
					<Link activeClassName="selected" to="/cv">
						CV
					</Link>
				</li>
			</ul>
		</MainMenu>
	</header>
);

Header.propTypes = {
	siteTitle: PropTypes.string,
};

Header.defaultProps = {
	siteTitle: ``,
};

export default Header;
