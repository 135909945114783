import React, {useRef, useEffect} from "react";
import PropTypes from "prop-types";
import Header from "components/layout/Header/Header";
import Footer from "components/layout/Footer/Footer";

// import Cursor from "theme/mouse-cursor";
// import ButtonCtrl from "theme/button-enter";

// Styles
import "assets/styles.scss";
import {BodyWrapper, CursorWrapper} from "./Body.styles.js";

function Body({children}) {
	const mouseRef = useRef(null);
	useEffect(() => {
		if (typeof window !== `undefined`) {
			const {MouseCursor} = module.require("theme/mouse-cursor-instance");
			setTimeout(() => {
				if (mouseRef !== null) {
					MouseCursor.init(mouseRef.current);
					// if (document.querySelector(".btn-enter")) {
					// 	const button = new ButtonCtrl(document.querySelector(".btn-enter"));
					// 	button.on("enter", () => cursor.enter());
					// 	button.on("leave", () => cursor.leave());
					// }
				}
			}, 150);
		}
	}, []);

	return (
		<>
			{/* <Header siteTitle={data.site.siteMetadata.title} /> */}
			<BodyWrapper>
				<main>{children}</main>
				{/* <Footer /> */}
			</BodyWrapper>

			<CursorWrapper
				ref={mouseRef}
				className={`cursor ${mouseRef === null ? "" : "cursor--set"}`}
				width="25"
				height="25"
				viewBox="0 0 25 25"
			>
				<circle className="cursor__inner" cx="12.5" cy="12.5" r="6.25" />
			</CursorWrapper>
		</>
	);
}

Body.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Body;
